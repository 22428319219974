<template>
    <div :style="`--silderDuration: ${transitionTime}ms`" >
      <div class="hps">
        <swiper class="swiper" ref="swiper" :options="swiperOptions" >
          <swiper-slide v-for="(item, index) in slides" class="hps-card" :data-index="index" :key="index + 0">
            <div class="ingredients-card">
              <div class="ingredients-card__content" >
                <div class="ingredients-card__img-wrapper" :style="{'background-image': !desktopScreen ? `url(${item.BackgroundImageForMobile})` : ''}">
                  <img
                    :src="desktopScreen ? item.BackgroundImageForDesktop : item.BackgroundImageForMobile" 
                    :alt="removeHtmlAndSpecialChars(item.SliderTitle)"
                    class="ingredients-card__img desktop"
                    v-show="desktopScreen"
                    :loading="index!== 0 ? 'lazy' : 'eager' "
                    :fetchpriority="index === 0 ?'high' :'auto' "
                  />
                </div>
                <div class="descritption-container" :class="[(item.SliderColor === 'white' || item.SliderColor === 'White') ? 'light' : 'dark']" >
                  <div class="mobile-background" :style="{'background-image': !desktopScreen ? `url(${item.BackgroundImageForMobile})` : ''}"></div>
                  <div class="slider-title" v-html="decodeEntities(item.SliderTitle)"></div>
                  <a :href="item.CtaLink" v-show="item.CtaLink !== '' && item.CtaLabel !== ''" title="" class="cta cta--light" @click="sliderButton(item)">
                    <span class="btn__wrapper">{{ item.CtaLabel }}</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="swiper-button">
              <div class="swiper-navigation-button swiper-button-prev" @click="prev()"  slot="button-prev">
                <svg  v-bind:style="`fill: ${item.SliderColor}`" xmlns="http://www.w3.org/2000/svg" width="42" height="8" viewBox="0 0 42 8" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M42 3.99996L38.1464 0.146484L37.4393 0.853599L40.0858 3.49999L0.13953 3.5V4.5L40.0858 4.49999L37.4393 7.14649L38.1465 7.85359L42 3.99996Z" />
                </svg>
              </div>
              <div class="swiper-navigation-button swiper-button-next" @click="next()" slot="button-next">
                <svg v-bind:style="`fill: ${item.SliderColor}`" xmlns="http://www.w3.org/2000/svg" width="42" height="8" viewBox="0 0 42 8" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M42 3.99996L38.1464 0.146484L37.4393 0.853599L40.0858 3.49999L0.13953 3.5V4.5L40.0858 4.49999L37.4393 7.14649L38.1465 7.85359L42 3.99996Z" />
                </svg>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" @click="pagination()"></div> 
        </swiper>
      </div>
    </div>
  </template>
  
  <script>
  import { Swiper as SwiperClass, Pagination, Autoplay, Navigation } from 'swiper/js/swiper.esm';
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  
  SwiperClass.use([Pagination, Autoplay, Navigation]);
  const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
  export default {
    name: 'home-page-slider-version2',
    components: { Swiper, SwiperSlide },
    props: {
      slides: { type: Array, required: true, default: undefined },
      staticMode: { type: Boolean, default: false },
      sliderDuration: { type: Number, default: 6 },
      pageCategory: { type: String, default: 'homepage' },
      ctaClass: { type: String, default: 'btn btn--white' },
    },
    data() {
      return {
        item: 0,
        itemNo: this.slides.length,
        transitionTime: (0 < this.sliderDuration ? this.sliderDuration : 6) * 1000,
        transition: null,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        swiperOptions: {
          loop: true,
          initialSlide: 0,
          speed: 500,
          slidesPerView: 1,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            type: "bullets"
          },
          navigation: { prevEl: '.swiper-navigation-button.swiper-button-prev', nextEl: '.swiper-navigation-button.swiper-button-next'},
          autoplay: { 
            delay: (0 < this.sliderDuration ? this.sliderDuration : 6) * 1000,
            disableOnInteraction: false,
          }
        },
        desktopScreen: window.matchMedia('(min-width: 1024px)').matches,
        swipeMethod:'',
        automaticslider: false,
        touchEnd: false
      }
    },
    created() {
      if (!this.staticMode) {
        this.autoTransition();
        this.automaticslider = true;
        this.swiperOptions.loop = true;
      }
      else {
        this.swiperOptions.loop = false;
        this.swiperOptions.autoplay = false;
      }
    },
    mounted() {
      window.eventBus.$emit('transparent-header', true);
      /* istanbul ignore next */
      this.$refs.swiper.swiperInstance.on('slideChange', (event) => {
        if(this.automaticslider && (this.swipeMethod != 'dot' && this.swipeMethod != 'arrow')) {  
          if(this.touchEnd == true) {
            this.swipeMethod = 'swipe';
          }else {
            this.swipeMethod =  'automatic slider';
          }
        }
        this.onSwipe();
        if(!this.automaticslider) {  
          this.swipeMethod = 'swipe';
        }
      });
      this.$refs.swiper.swiperInstance.on('touchEnd', (event) => {
       this.touchEnd = true;
      });
    },
    methods: {
      decodeEntities(encodedString) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = encodedString;
        return textarea.value;
      },
      onSwipe() {
        this.navigationTagging();
      },
      prev() {
        this.swipeMethod = 'arrow';
      },
      next() {
        this.swipeMethod = 'arrow';
      },
      pagination() {
        this.swipeMethod = 'dot';
      },
      sliderButton(item) { 
        let mainUrl = window.location.origin;
        let ctalink = item.CtaLink;
        let destinationUrl;
        if(item.CtaLink == '/') {
          destinationUrl  = mainUrl;
        }else {
          destinationUrl  = ctalink ? mainUrl+ctalink :  'none';
        }
        let taggingObj = {
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': 'homepage',
          'eventAction': 'select::slider navigation',
          'eventLabel': `${this.removeHtmlAndSpecialChars(item.SliderTitle).toLowerCase()}::${destinationUrl.toLowerCase()}`,
          'cta_name': `${item.SliderTitle && this.removeHtmlAndSpecialChars(item.SliderTitle).toLowerCase()}`,
          'module_name': 'homepage_slider',
          'link_url':destinationUrl.toLowerCase(),
        }   
        AnalyticsHandler.pushDataLayer(taggingObj);
      },
      navigationTagging() {
        let indexvalue;
        if(this.automaticslider) { 
          indexvalue = this.$refs.swiper.$swiper.realIndex + 1 ; 
        }else {
          indexvalue = this.$refs.swiper.$swiper.activeIndex + 1 ; 
        }
        let currentEle = this.slides[indexvalue-1];
        console.log(indexvalue,this.swipeMethod);
        let taggingObj = {
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': 'homepage',
          'eventAction': 'select::slider navigation elements',
          'eventLabel': `${this.swipeMethod ? this.swipeMethod : 'swipe' }::${this.removeHtmlAndSpecialChars(currentEle.SliderTitle).toLowerCase()}::${indexvalue}`,
          'cta_name': `${this.swipeMethod ? this.swipeMethod : 'swipe'}::${this.removeHtmlAndSpecialChars(currentEle.SliderTitle).toLowerCase()}::${indexvalue}`,
          'module_name': `slider navigate::${this.swipeMethod ? this.swipeMethod : 'swipe'}`
        }
        AnalyticsHandler.pushDataLayer(taggingObj);
        this.swipeMethod = '';
      },
      removeHtmlAndSpecialChars(inputString) {
        // Create a DOMParser to parse the inputString as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(inputString, 'text/html');
      
        // Get the plain text without HTML tags
        const plainText = doc.body.textContent || "";

        return plainText.replaceAll('\n', '');
      },
      autoTransition() {
        this.transition = setInterval(() => {
          this.transitionSlide()
        }, this.transitionTime)
      },
      transitionSlide() {
        this.item = this.item + 1;
        if (this.item > this.itemNo-1) {
          this.item = 0;
        }
        this.touchEnd = false;
      },
    },
    beforeDestroy() {
      clearInterval(this.transition);
    },
  }
  </script>
  <style lang="scss" src="./home-page-transparency-slider.scss"></style>
  