<template>
  <div class="product-list" :id="productListId">
    <template v-if="!products">
      <slot name="title" />
      <slot :productpopin="openProductPopin" />
    </template>
    <template v-else>
      <slot name="title" v-if="showTitle" />
      <ul class="product-list container--is-maxwidth" :class="getListType">
        <li class="product-list__item" v-for="(product, index) in products" :key="index">
          <input type="hidden" :value="JSON.stringify(product)" :data-product-id="`${product.productId}`">
          <article-card
            v-if="renderItems && (getListType === 'article' || getListType === 'simple')"
            :src-set="product.srcset"
            :img-src="product.src"
            :img-alt="product.alt"
            :item-url="product.href"
            :item-title="product.title"
            :tagging="{ label: product.titleNoHtml, category: product.pageType }"
            :cta-label="ctaLabelDecoded"
          />

          <!-- ingredient card -->
          <template v-else-if="renderItems && getListType === 'ingredient'">
            <div class="ingredient">
              <a
                :href="product.ingredientLink"
                class="ingredient__name"
                tabindex="0"
                v-html="product.ingredientName"
                v-tag:useractionevent="{
                  action: product.action,
                  label: product.ingredientName,
                  category: product.category,
                }"
              />
              <p class="ingredient__content" v-html="product.ingredientContent" />
              <a :href="product.ingredientLink" :class="ctaClass">
                <span class="btn__wrapper">
                  {{ ctaLabelDecoded }}
                </span>
              </a>
            </div>
          </template>

          <!-- concern card -->
          <concern-card
            v-else-if="
              renderItems &&
              (getListType === 'concern' || getListType === 'range' || getListType === 'mol')
            "
            :src-set="product.srcset"
            :img-src="product.src"
            :img-alt="product.alt"
            :item-url="product.href"
            :item-title="product.description"
            :tagging="{ label: product.descriptionNoHTml, category: product.pageType }"
            :cta-label="ctaLabelDecoded"
          />

          <product-card
            v-else-if="(!listItems && getListType === '') || getListType === 'product'"
            :product-name="{ text: product.productName, html: product.productNameHtml }"
            :product-short-description="product.productShortDescription"
            :product-url="product.productUrl"
            :tagging="product.tagging"
            :packshot="product.packshot"
            :cta-label="ctaLabelDecoded"
            :star-rating-text="product.starRatingText"
            :average-rating="Number(product.averageRating)"
            :inactive-color="product.inactiveColor"
            :active-color="product.activeColor"
            :is-rating-enabled="`${(String(product.isRatingEnabled).toLowerCase() === 'true')}`"
            :is-bazaarvoice-activated="`${(String(product.IsBazaarvoiceActivated).toLowerCase() === 'true')}`"
            :border-color="product.activeColor"
            :product-id="`${product.productId}`"
            :product-item-id="`${product.productItemId}`"
            border-width="1"
            aria-hidden="true"
            :is-buynow-enabled="`${(String(buyNowEnable).toLowerCase() === 'true')}`"
            :buynow-cta-text="buyNowLabel"
            :total-rating-count="product.starRatingText"
            data-variant-ean=""
            initial-data=""
            data-bynow-tagging=""
            error-messgage=""
            :product-data-id="product.productId"
            :page-category="pageCategory"
          />
        </li>
      </ul>
      <slot name="loadmore" />
    </template>
  </div>
</template>
<script>
import { productsMapper } from '../../services/products-mapper';
import { mapGetters } from 'vuex';
// remove when implement cards for list types
import { tag } from '@Foundation/analyticsHandler';

export default {
  name: 'product-list',

  directives: {
    tag,
  },

  components: {
    'product-card': () => import('../product-card/product-card.vue'),
    'article-card': () => import('../article-card/article-card.vue'),
    'concern-card': () => import('../concern-card/concern-card.vue'),
  },

  props: {
    listItems: {
      type: Array,
      default() {
        return null;
      },
    },
    productListId: {
      type: String,
      required: true,
      default: undefined,
    },
    ctaLabel: {
      type: String,
      required: false,
      default: '',
    },
    ctaClass: {type: String, default: 'cta cta--light'},
    buyNowLabel: {
      type: String,
      default: ''
    },
    buyNowEnable: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      items: null,
      renderItems: false,
      pageCategory: ''
    };
  },

  computed: {
    ...mapGetters(['getListType']),
    products() {
      return this.$store.getters.getProducts(this.productListId);
    },
    showTitle() {
      return !!this.products.length;
    },
    ctaLabelDecoded() {
      return this.decodeHTML(this.ctaLabel);
    },
  },

  watch: {
    getListType() {
      this.initInStore();
      this.renderItems = true;
    },
  },

  created() {
    this.initInStore();
  },
  mounted() {
    this.pageCategory =  window.wsf.AnalyticsVariables.pageCategory ?  window.wsf.AnalyticsVariables.pageCategory : ''
  },
  destroyed() {
    this.$store.commit('deleteProducts', this.productListId);
  },
  methods: {
    decodeHTML(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    },

    initInStore() {
      this.$nextTick(() => {
        if (this.listItems) {
          this.$store.commit('products', {
            productListId: this.productListId,
            value: this.makeItems(),
          });
          this.$store.commit('initialItemsSize', this.makeItems().length);
        } else {
          this.$store.commit('products', {
            productListId: this.productListId,
            value: this.listItems,
          });
        }
      });
    },
    makeItems() {
      if (this.getListType !== 'product') {
        return this.listItems;
      } else {
        return productsMapper(this.listItems);
      }
    },
    openProductPopin(productId) {
      window.eventBus.$emit('openProductPopin', {
        id: productId,
        disableColorTheme: false
      })
    }
  },
};
</script>
<style lang="scss" src="./product-list.scss"></style>
