<template>
  <div>
    <div class="modal__open-btn" @click.prevent="openModal" ref="openModal">
      <slot name="button" :ally="showModal"/>
    </div>

    <div class="modal"
         v-show="showModal"
         :aria-labelledby="ariaLabelledby"
         :aria-describedby="ariaDescribedby">

      <div class="modal__backface"/>

      <div class="modal__wrapper" :class="showModal ? '--opened' : ''" ref="wrapper">
        <button class="modal__close-btn"
                @click="closeModal"
                @keyup.esc="closeModal"
                type="button">
          <span class="is-sr-only">{{ closeAriaLabel }}</span>
        </button>

        <div class="modal__content">
          <slot/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'modal',
    props: {
      closeAriaLabel: {
        type: String,
        required: false,
        default: undefined
      },
      closeLabel: {
        type: String,
        required: false,
        default: '&times;'
      },
      ariaLabelledby: {
        type: String,
        required: false,
        default: undefined
      },
      ariaDescribedby: {
        type: String,
        required: false,
        default: undefined
      }
    },
    data() {
      return {
        showModal: false,
        deferredPrompt: null
      };
    },
    methods: {
      openModal() {
        this.showModal = true;

        this.$nextTick(() => {
          this.setFocus();
        });
      },
      closeModal() {
        this.showModal = false;
        this.$refs.openModal.querySelector('button').focus();
      },
      setFocus() {
        this.$el.querySelector('.modal__close-btn').focus();
      }
    }
  };
</script>

<style lang="scss" src="./modal.scss"></style>
