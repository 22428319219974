<template>
  <div v-if="showNewsletterSticky" class="newsletter-sticky" ref="newsletterSticky" :class="[ newsletterScroll ? 'show-newsletter-sticky':'hide-newsletter-sticky',position]">
    <div class="newsletter-sticky__container">
      <p :style="{color: textColor}" v-html="newsletterStickyText"></p>
      <button @click="showNewsLetterPopin()">
        <img class="newsletter-sticky__arrow"
          alt="open newsletter"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMi4wMTExOEwxMC41MDAyIDExTDE5IDIiIHN0cm9rZT0iIzAwOUVFMiIgc3Ryb2tlLXdpZHRoPSIzIi8+Cjwvc3ZnPgo="
        />
      </button>
      <button id="btn_close" class="btn_close close" aria-label="Close" @click="closeStickyBar(true)"></button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'newsletter-sticky',
  props: {
    newsletterStickyText: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '#000000',
    },
    pageCategory: {
      type: String,
      default:''
    }
  },
  data() {
    return {
      showNewsletterSticky: false,
      newsletterScroll: true
    };
  },
  created() {
    this.initialRendering();
  },
  mounted() {
    window.addEventListener('scroll',() => {
      var el = this.$refs.newsletterSticky;
      if(el != null) {
        const currentScroll = window.pageYOffset;
        if(currentScroll === 0) {
          this.newsletterScroll = true;
        }else{
          this.newsletterScroll = false;
        }
      }
    });
  },
methods: {
    closeStickyBar(isClose = false) {
      this.showNewsletterSticky = false;
      document.cookie = 'newsletterSticky=true';
      if(isClose) {
          let action = 'close';
          let desktopScreen = window.matchMedia('(min-width: 767px)').matches;
          let pos = desktopScreen ? 'top' : this.position;
          window.dataLayer.push({
            'event': 'uaevent',
            'ecommerce': 'undefined',
            'event_name':'sticky_bar',
            'eventCategory': this.pageCategory,
            'eventAction': 'newsletter_sticky',
            'eventLabel': action +' '+ pos.toLowerCase(),
            'module_name':'newsletter_sticky',
            'cta_name': action +' '+ pos.toLowerCase()
        });
      }
    },
    initialRendering() {
      const getStickyCookie = document.cookie
        .split(';')
        .filter((cookieItem) => cookieItem.includes('newsletterSticky'));
      window.eventBus.$on('newsletterSticky', () => {
        this.showNewsletterSticky = !getStickyCookie.length;
      });
      window.eventBus.$on('newsletterStickyClose', () => {
        this.closeStickyBar()
      });
    },
    showNewsLetterPopin() {
      this.showNewsletterSticky = false;
      let desktopScreen = window.matchMedia('(min-width: 767px)').matches;
      let pos = desktopScreen ? 'top' : this.position;
      window.eventBus.$emit('newsletterpopup', {postion: pos, show: true});
      let body = document.getElementsByTagName("body");
      if(body) {
        body[0].classList.add("newsletterPopup");
      }
      let action = 'click';
      window.dataLayer.push({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name':'sticky_bar',
          'eventCategory': this.pageCategory,
          'eventAction': 'newsletter_sticky',
          'eventLabel': action +' '+ pos.toLowerCase(),
          'module_name':'newsletter_sticky',
          'cta_name': action +' '+ pos.toLowerCase()
      });
    },    
  },
  destroyed(){
    window.removeEventListener('scroll');
  }
};
</script>
