<template>
  <div class="header-banner">
    <div class="component-content" :class="'headerv2-image-'+dataOption">
      <banner class="banner" :class="[mediumImage === 'true' ? 'small-banner': '', '-'+textColor]" :srcset="srcset" content-color="" :is-generic-banner="true">
        <img src="/frontend/master/images/placeholder.png" alt="test" slot="background">
        <noscript>
          <!-- SEO-->
          <img src="/frontend/master/images/placeholder.png" alt="test">
        </noscript>
        <div v-if="title || descripion || linkText"  class="container--is-maxwidth"  v-bind:class="['bannerv2-'+horizontalAlign,verticalAlign]" :style="{'text-align':horizontalAlign, 'align-items': textAlign}">
          <div v-if="title" class="banner__title">
            <h1 class="heading" v-html="title" ></h1>
          </div>
          <div class="banner__text" slot="text" >
              <p v-html="descripion"></p>
          </div>
          <a v-if="linkText" :href="link" :class="ctaClass">
              <div class="btn__wrapper">
                {{ linkText }}
              </div>
          </a>
        </div>
      </banner>
    </div>
  </div>
</template>
<script>
  import Banner from '@Feature/Banner/code/Scripts/components/banner.vue';

  export default {
    name: 'header-banner',
    components: { Banner },
    props: {
      srcset: {
        type: [Object, String],
        required: true,
        default: undefined
      },
      verticalPosition: {
        type: String,
        required: false,
        default: 'vertical-center'
      },
      horizontalPosition: {
        type: String,
        required: false,
        default: 'text-center'
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      link: {
        type: String,
        required: false,
        default: ''
      },
      linkText: {
        type: String,
        required: false,
        default: ''
      },
      descripion: {
        type: String,
        required: false,
        default: ''
      },
      textColor: {
        type: String,
        required: false,
        default: 'white'
      },
      image: {
        type: String,
        required: false,
        default: ''
      },
      mediumImage: {
        type: String,
        required: true,
        default: 'false'
      },
      dataOption :{
        type: String,
        required: true,
        default: 'large'
      },
      htag: {
        type: String,
        required: false,
        default: 'h1'
      },
      ctaClass: {type: String, default: 'cta cta--light'},
    },
    data() {
      return {
        verticalAlign: 'vertical-center',
        horizontalAlign: 'center',
        textAlign: 'center'
      };
    },
    created() {
      if (this.verticalPosition === 'VerticalCenter') {
        this.verticalAlign = 'vertical-center';
      } else if (this.verticalPosition === 'VerticalBottom') {
        this.verticalAlign = 'vertical-bottom';
      } else {
        this.verticalAlign = 'vertical-top';
      }

      if (this.horizontalPosition === 'TextLeft') {
        this.horizontalAlign = 'left';
        this.textAlign = 'flex-start';
      } else if (this.horizontalPosition === 'TextCenter') {
        this.horizontalAlign = 'center';
      } else {
        this.horizontalAlign = 'right';
        this.textAlign = 'flex-end';
      }
    },
  };
</script>
<style lang="scss" src="./header-banner.scss"></style>
