<template>
  <div :class="classes">
    <div class="responsive-image__media" ref="backgroundWrapper">
      <div :style="parallaxCss" class="parallax" :class="{ active: isParallaxActive }" ref="parallax"/>
      <slot/>
      <slot name="content"/>
    </div>
  </div>
</template>
<script>
  import { getBackground } from '@Foundation/directives/srcset/srcset';
  import { debounce } from '@Foundation/utilities';
  import { intersectionViewportObserver } from '@Foundation/utilities/intersectionViewportObserver';

  export default {
    name: 'responsive-image',
    props: {
      srcset: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      aspectRatio: {
        type: String,
        required: false,
        default: undefined
      },
      animate: {
        type: String,
        required: false,
        default: 'zoom'
      },
      enableDsfService: {
        type: Boolean,
        required: false,
        default: false
      },
      isMobileVersionEnabled: {
        type: Boolean,
        required: false,
        default: false
      },
      mobileVersionImage: {
        type: String,
        required: false,
        default: ''
      },
      desktopImages: {
        type: [Object, String],
        required: false,
        default: undefined
      },
    },

    data() {
      return {
        loaded: true,
        isParallaxActive: false,
        backgroundImage: undefined,
        translateParallax: 'translate3d(0px, 0px, 0px)',
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        smallScreenDevice: window.MqHandler
          ? window.MqHandler.getMqForSize('small').selected
          : true,

      };
    },
    computed: {
      parallaxCss() {
        return {
          backgroundImage: this.backgroundImage,
          transform: this.translateParallax
        };
      },
      classes() {
        return [
          'responsive-image',
          this.aspectRatio ? `-ratio -ratio--${this.aspectRatio}` : '',
          this.animate ? `-${this.animate}` : '',
          !!this.$slots.content ? '-gradient' : '',
          this.loaded ? '-loaded' : ''
        ];
      }
    },

    mounted() {
      this.img = this.$refs.backgroundWrapper.querySelector('img');
      intersectionViewportObserver(this.$el, { checkIsVisible: false }).then(() => {
        this.loaded = true;
        this.updateBackground();

        /* istanbul ignore else */
        if (this.animate === 'parallax') {
          this.parallax();
        }

        /* istanbul ignore else */
        if (this.srcset) {
          /* istanbul ignore next */
          this.responsiveListener = debounce(() => this.updateBackground(), 200);
          window.addEventListener('resize', this.responsiveListener);
        }
      });
    },

    destroyed() {
      /* istanbul ignore else */
      if (this.responsiveListener) {
        window.removeEventListener('resize', this.responsiveListener);
        this.responsiveListener = null;
      }
    },
    // need for EE
    updated() {
      /* istanbul ignore next */
      if (document.getElementsByTagName('body')[0].classList.contains('pagemode-edit')) {
        this.updateBackground();
      }
    },

    methods: {
      /**
       * add css transitions to parallax element
       * @returns {undefined}
       */
      parallaxTransitions() {
        const parallaxElm = this.$refs.parallax.getBoundingClientRect();

        if (parallaxElm.y <= 0 && parallaxElm.height > -(parallaxElm.y)) {
          this.isParallaxActive = true;
          this.translateParallax = `translate3d(0px, ${-(parallaxElm.y / 8)}px, 0px)`;
        } else {
          this.isParallaxActive = false;
          this.translateParallax = 'translate3d(0px, 0px, 0px)';
        }
      },

      /**
       * add scroll listener to add the parallax effect
       * @returns {undefined}
       */
      parallax() {
        window.addEventListener('scroll', this.parallaxTransitions);
      },
      updateBackground() {
        var src = this.getBackgroundImage();

        if (this.enableDsfService && this.isMobileVersionEnabled && this.mobileVersionImage && this.smallScreen) {
          this.backgroundImage = `url('${this.mobileVersionImage}')`;
        } else if (this.enableDsfService && this.isMobileVersionEnabled && this.mobileVersionImage == '' && this.smallScreen) {
          this.backgroundImage = `url('${this.desktopImages.imagelow}')`;
        } else {
          if (this.srcset) {
            var result = Object.keys(this.srcset).map((key) => [Number(key), this.srcset[key]]);
            if (src === '') {
              if (result[1][1]) {
                src = result[1][1];
              }
              if (result[0][0]) {
                src = result[0][0];
              }
            }
          }
          this.backgroundImage = `url('${src}')`;
        }


        const img = this.$refs.backgroundWrapper.querySelector('img');
        /* istanbul ignore else */
        if (img) {
          img.src = src;
        }
      },

      getBackgroundImage() {
        /* istanbul ignore else */
        if (this.srcset) {
          return getBackground(this.srcset);
        }

        const elImg = this.$el.querySelector('img');
        /* istanbul ignore else */
        if (elImg && elImg.src) {
          return elImg.src;
        }

        /* istanbul ignore next */
        return undefined;
      }
    }
  };
</script>
<style lang="scss" src="./responsive-image.scss"></style>
