<template>
  <div class="articleHeader__container">
    <div class="articleHeader">
    <picture class="articleHeader__image">
      <source :srcset="initialData.NewArticleDesktopImage" media="(min-width: 48em)" />
      <source :srcset="initialData.NewArticleMobileImage" media="(max-width: 48em)" />
      <img
        :src="initialData.NewArticleMobileImage"
        :alt="isMobile ? initialData.ArticleImageAlt : initialData.ArticleImageAlt"
        loading="lazy"
      />
    </picture>
    <div class="articleHeader__content">
      <div class="articleHeader__content-subtitle" v-if="initialData.ArticleSubTitle" v-html="initialData.ArticleSubTitle"></div>
      <div class="articleHeader__content-title">
        <h1 v-html="initialData.ArticleHeaderTitle"></h1>
      </div>
      <div class="articleHeader__content-category">
        <div class="articleHeader__content-category_icon" v-if="initialData.Category != ''">
          <img :src="initialData.CategoryIcon" :alt="initialData.Category" v-if="initialData.CategoryIcon !== ''"> 
          <div class="articleHeader__content-category_icon_text" v-if="initialData.Category"><strong>{{initialData.Category}}</strong></div>
        </div> 
        <div class="articleHeader__content-category_read" v-if="initialData.DurationInfo != ''">  
          <div class="articleHeader__content-category_read-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <circle cx="9.99928" cy="10.0001" r="6.11654" stroke="white" stroke-width="1.35923"/>
                  <path d="M10 6.60202V10.5664H12.8317" stroke="white" stroke-width="1.35923"/>
                </svg>
          </div>         
          <div class="articleHeader__content-category_read-text">
              {{initialData.DurationInfo}}
          </div>
        </div>
      </div>
    </div>
   </div>
    <div class="articleHeader__intro" v-if="initialData.Introduction !== ''">
      <div class="articleHeader__introTxt container--is-maxwidth" v-html="initialData.Introduction"/>
    </div>  
   
  </div> 
</template>

<script>
export default {
  name: 'article-header',
  props: {
    initialData: {
      type: Object,
      default: () => {},
    },
    pageCategory: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 1024;
    window.eventBus.$emit('transparent-header', true);
  },
};
</script>
<style lang="scss" src="./article-header.scss"></style>
