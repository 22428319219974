import Vue from 'vue';
import Vuex from 'vuex';

import { productsStore } from '@Feature/LrpProducts';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    products: productsStore
  }
});
