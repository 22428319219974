export const newsletterclick = { // eslint-disable-line
  name: 'newsletterclick',
  bind: (el, binding) => {

    el.addEventListener('click',()=>{
      if(binding.value === true){
        let desktopScreen = window.matchMedia('(min-width: 767px)').matches;
        let pos = desktopScreen ? 'top' : this.position;
        window.eventBus.$emit('newsletterpopup', {postion: pos, show: true});
      }else{
        window.eventBus.$emit('newsletterpopup', {postion: pos, show: false});
      }
    });
  }
};
