<template>
  <div class="generic__banner__component generic__banner__component__banner">
    <!-- <pre> {{initialData}} </pre> -->
    <div id="Frames">
      <!--- OPTION2-->

      <div class="generic__banner_component container--is-maxwidth" :class="'hight--'+initialData.PresentationOption" v-if="initialData.PresentationOption === 'Option2' ">
        <div class="generic__banner__component__section" :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">
          <div :class="initialData.PresentationOption">
            <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']" v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' && !smallScreen">
            </div>
            <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']"  v-if="initialData.TitleAtHnTag != 'NotSelected' && !smallScreen">

              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>
            </div>
            <div class="generic__banner__component__text__title" v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'"></div>
            <div class="generic__banner__component__text__title" v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'" >
              <h1 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
              <h2 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
              <h3 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>

            </div>

            <div class="generic__banner__component__image parallax">
              <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">

                <div  class="focuspoint focal-frame"  :style="getScreenWidth('option2')"
                      :data-focus-x="isRtl === false ? initialData.DesktopFocusX :''"
                      :data-focus-y="isRtl === false ? initialData.DesktopFocusY: ''"
                >
                                          <span class="">
                                             <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image"/>
                                          </span>
                </div>
              </div>
              <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
                <div  class="focuspoint focal-frame"  :style="getScreenWidth('option2')"
                      :data-focus-x="isRtl === false ? initialData.MobileFocusX : ''"
                      :data-focus-y="isRtl === false ? initialData.MobileFocusY : ''"
                >
                                             <span class="">
                                                <img loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                             </span>
                </div>
              </div>
            </div>
            <div class="generic__banner__component__text__description"   v-html="initialData.DesktopText" v-if="!smallScreen" ></div>
            <div class="generic__banner__component__text__description"   v-text="initialData.MobileText" v-html="titleMobileDescription(initialData.MobileText,initialData.DesktopText,initialData.EnableMobileVersion)" v-if="smallScreen">
            </div>

          </div>
        </div>
      </div>
      <!--- OPTIO2-->
      <!--- OPTION1-->
      <div class="generic__banner_component  container--is-maxwidth" :class="'hight--'+initialData.PresentationOption"  v-if=" initialData.PresentationOption == 'Option1' ">
        <div class="generic__banner__component__section" :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">
          <div :class="initialData.PresentationOption">

            <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']" v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' && !smallScreen">
            </div>
            <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']"  v-if="initialData.TitleAtHnTag != 'NotSelected' && !smallScreen">

              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>
            </div>


            <div class="generic__banner__component__text__title" v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'" ></div>
            <div class="generic__banner__component__text__title" v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'" >
              <h1 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
              <h2 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
              <h3 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>

            </div>
            <div class="generic__banner__component__text__description"   v-html="initialData.DesktopText" v-if="!smallScreen"></div>
            <div class="generic__banner__component__text__description"   v-html="titleMobileDescription(initialData.MobileText,initialData.DesktopText,initialData.EnableMobileVersion)" v-if="smallScreen"> </div>
            <div class="generic__banner__component__image parallax">
              <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">
                <div  class="focuspoint focal-frame"  :style="getScreenWidth('option1')"
                      :data-focus-x="isRtl === false ? initialData.DesktopFocusX :''"
                      :data-focus-y="isRtl === false ? initialData.DesktopFocusY:''"
                >
                                          <span class="">
                                             <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image"/>
                                          </span>
                </div>
              </div>
              <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
                <div  class="focuspoint focal-frame"  :style="getScreenWidth('option1')"
                      :data-focus-x="isRtl === false ? initialData.MobileFocusX : ''"
                      :data-focus-y="isRtl === false ? initialData.MobileFocusY: ''"
                >
                                             <span class="">
                                                <img loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                             </span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!--- OPTION1-->
      <!--- OPTION6-->
      <div class="generic__banner_component " :class="'hight--'+initialData.PresentationOption" v-if=" initialData.PresentationOption == 'Option6' ">
        <div :class="initialData.PresentationOption">
          <div class="generic__banner__component__section" :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">

            <div class="generic__banner__component__image parallax">
              <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">
                <div  class="focuspoint focal-frame"  :style="getScreenWidth()"
                      :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                      :data-focus-y="isRtl === false ? initialData.DesktopFocusY :''"
                >
                                          <span class="">
                                             <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image"/>
                                          </span>
                </div>
              </div>
              <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
                <div  class="focuspoint focal-frame"  :style="getScreenWidth()"
                      :data-focus-x="isRtl === false ? initialData.MobileFocusX :''"
                      :data-focus-y="isRtl === false ? initialData.MobileFocusY : ''"
                >
                                             <span class="">
                                                <img loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                             </span>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
      <!--- OPTION6-->
      <!------FullWidth -->  <!--- working-->
      <div class="generic__banner_component__fullwidth" v-if="initialData.PresentationOption === 'Option5' ">
        <div class="generic__banner__component__section "       :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">
          <div class="generic__banner__component__image parallax">

            <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">
              <div  class="focuspoint focal-frame"  :style="getScreenWidth()"
                    :data-focus-x="isRtl === false ? initialData.DesktopFocusX :''"
                    :data-focus-y="isRtl === false ? initialData.DesktopFocusY:''"
              >
                                          <span class="">
                                             <img loading="lazy" class="original" :src="initialData.New_Image"/>
                                          </span>
              </div>
            </div>
            <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
              <div  class="focuspoint focal-frame"  :style="getScreenWidth()"
                    :data-focus-x="isRtl === false ? initialData.MobileFocusX :''"
                    :data-focus-y="isRtl === false ? initialData.MobileFocusY :''"
              >
                                             <span class="">
                                                <img fetchpriority="high" loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                             </span>
              </div>
            </div>

          </div>
          <div :class="['alignment-'+initialData.TextpositiononImage,initialData.TextpositiononImage === 'Right' ? 'container--is-maxwidth' : '']">
            <div class="generic__banner__component__text"  :class="[initialData.WhiteColorOption === true ? 'whitecolor-enabled'  : '',initialData.TextpositiononImage === 'Center' ? 'container--is-maxwidth' : ''  ]" v-if="!smallScreen">
              <div :class="initialData.TextpositiononImage">
                <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : ''  ]" v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected'">
                </div>
                <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']"  v-if="initialData.TitleAtHnTag != 'NotSelected'">

                  <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                  <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                  <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>
                </div>
                <div class="generic__banner__component__text__description"   v-html="initialData.DesktopText">
                </div>
                <div class="generic__banner__component__text__cta" v-if="initialData.DesktopCTALabel!=''">
                  <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass">
                    <span class="btn__wrapper">{{initialData.DesktopCTALabel}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div :class="initialData.TextpositiononImage">
            <div class="generic__banner__component__text" v-if="smallScreen ">
              <div class="generic__banner__component__text__title"  v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'" >
              </div>
              <div class="generic__banner__component__text__title" v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'" >
                <h1 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                <h2 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                <h3 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>

              </div>
              <div class="generic__banner__component__text__description" v-html="titleMobileDescription(initialData.MobileText,initialData.DesktopText,initialData.EnableMobileVersion)" v-if="smallScreen">
              </div>
              <div v-if="initialData.EnableDSFService === false">
                <div class="generic__banner__component__text__cta" v-if="initialData.MobileCTALabel !='' && initialData.EnableMobileVersion">
                  <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                                 {{initialData.MobileCTALabel}}
                                 </span></a>
                </div>
                <div class="generic__banner__component__text__cta" v-if="initialData.DesktopCTALabel !='' && initialData.EnableMobileVersion==false  ">
                  <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                                 {{initialData.DesktopCTALabel}}
                                 </span></a>
                </div>
              </div>
              <div v-if="initialData.EnableDSFService === true">
                <div class="generic__banner__component__text__cta btn">
                  <spotscan-embed :data-dsf-tagging="dsfTagging" :data-tenant="dsfData.Tenant" :data-application="initialData.DSFApplication" :data-customer="dsfData.Customer" :data-country="dsfData.Country"
                                  :data-environment="dsfData.Environment" :data-touchpoint="dsfData.Touchpoint" :data-type="dsfData.Type" :data-loadingbg="dsfData.Loadingbackgroundcolor"
                                  :data-loadingmsg="dsfData.Loadingmessage" csrf-token="$WEBSITE_TOKEN" :switch-ecom="dsfData.Switchecom" :switch-autostart="dsfData.Switchautostart"
                                  :data-cta-label="ctalable(initialData.MobileCTALabel,initialData.DesktopCTALabel,initialData.EnableMobileVersion)" :data-pagecategory="dsfPageCategory" :data-cta="ctaClass" ></spotscan-embed>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!------FullWidth -->

      <!--HalfWidth-->

      <div class="generic__banner_component__halfwidth" v-if="initialData.PresentationOption === 'Option3' || initialData.PresentationOption === 'Option4' ">
        <div :class="initialData.PresentationOption" v-if="initialData.PresentationOption === 'Option3'">
          <div class="generic__banner__component__section" :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">
            <div class="generic__banner__component__image_half">
              <div class="generic__banner__component__image parallax">
                <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">
                  <div  class="focuspoint focal-frame"  :style="getScreenWidthHalf()"
                        :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                        :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''"
                  >
                                          <span class="">
                                             <img loading="lazy" class="original" :src="initialData.New_Image"/>
                                          </span>
                  </div>
                </div>
                <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
                  <div  class="focuspoint focal-frame"  :style="getScreenWidthHalf()"
                        :data-focus-x="isRtl === false ? initialData.MobileFocusX :''"
                        :data-focus-y="isRtl === false ? initialData.MobileFocusY : ''"
                  >
                                             <span class="">
                                                <img loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                             </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="generic__banner__component__text" v-if="!smallScreen">
              <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']" v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' ">
              </div>
              <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']"  v-if="initialData.TitleAtHnTag != 'NotSelected' ">

                <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>
              </div>



              <div class="generic__banner__component__text__description"   v-html="initialData.DesktopText">
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.DesktopCTALabel!=''">
                <a :href="initialData.DesktopCTALink" @click="gaTagging()">
                  <div :class="ctaClass">
                           <span class="btn__wrapper">
                              {{initialData.DesktopCTALabel}}
                           </span>
                  </div>
                </a>
              </div>
            </div>
            <div class="generic__banner__component__text" v-if="smallScreen">
              <div class="generic__banner__component__text__title" v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'" >
              </div>
              <div class="generic__banner__component__text__title" v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'" >
                <h1 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                <h2 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                <h3 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>

              </div>

              <div class="generic__banner__component__text__description"   v-html="titleMobileDescription(initialData.MobileText,initialData.DesktopText,initialData.EnableMobileVersion)">
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.MobileCTALabel != '' && initialData.EnableMobileVersion ">
                <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                     {{initialData.MobileCTALabel}}
                     </span></a>
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.EnableMobileVersion == false  && initialData.DesktopCTALabel !='' ">
                <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                     {{initialData.DesktopCTALabel}}
                     </span></a>
              </div>
            </div>
          </div>
        </div>
        <!----left-->
        <div :class="initialData.PresentationOption"  v-if="initialData.PresentationOption === 'Option4'">
          <div class="generic__banner__component__section" :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom': '']">

            <div class="generic__banner__component__text" v-if="!smallScreen">
              <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']" v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' ">
              </div>
              <div class="generic__banner__component__text__title" v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title'  : '']"  v-if="initialData.TitleAtHnTag != 'NotSelected' ">
                <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>
              </div>
              <div class="generic__banner__component__text__description"   v-html="initialData.DesktopText">
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.DesktopCTALabel!=''">
                <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                     {{initialData.DesktopCTALabel}}
                     </span></a>
              </div>
            </div>
            <div class="generic__banner__component__text" v-if="smallScreen">
              <div class="generic__banner__component__text__title" v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'"  >
              </div>
              <div class="generic__banner__component__text__title" v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'" >
                <h1 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H1' "> </h1>
                <h2 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H2' "> </h2>
                <h3 v-html="titleMobileText(initialData.MobileTitle,initialData.DesktopTitle,initialData.EnableMobileVersion)" v-if="initialData.TitleAtHnTag == 'H3' "> </h3>

              </div>
              <div class="generic__banner__component__text__description"   v-html="titleMobileDescription(initialData.MobileText,initialData.DesktopText,initialData.EnableMobileVersion)">
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.MobileCTALabel != '' && initialData.EnableMobileVersion ">
                <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                     {{initialData.MobileCTALabel}}
                     </span></a>
              </div>
              <div class="generic__banner__component__text__cta" v-if="initialData.EnableMobileVersion == false  && initialData.DesktopCTALabel !='' ">
                <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                     {{initialData.DesktopCTALabel}}
                     </span></a>
              </div>
            </div>
            <div class="generic__banner__component__image_half">
              <div class="generic__banner__component__image parallax">

                <div class="focal-frames" v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion===false))">
                  <div  class="focuspoint focal-frame"  :style="getScreenWidthHalf()"
                        :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                        :data-focus-y="isRtl === false ? initialData.DesktopFocusY :''"
                  >
                                             <span class="">
                                                <img loading="lazy" class="original" :src="initialData.New_Image"/>
                                             </span>
                  </div>
                </div>
                <div class="focal-frames" v-if="smallScreen && initialData.EnableMobileVersion===true && initialData.MobileImage !== ''">
                  <div  class="focuspoint focal-frame"  :style="getScreenWidthHalf()"
                        :data-focus-x="isRtl === false ? initialData.MobileFocusX: ''"
                        :data-focus-y="isRtl === false ? initialData.MobileFocusY :''"
                  >
                                                <span class="">
                                                   <img loading="lazy" class="original" :src="imageMobile(initialData.New_Image,initialData.MobileImage,initialData.EnableMobileVersion)"/>
                                                </span>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
        <!----->

      </div>
    </div>
    <!--HalfWidth-->
  </div>

</template>

<script>
import SpotscanEmbed from '@Feature/SpotscanEmbed/code/Scripts/components/spotscan-embed.vue';
import LoadScript from '@Foundation/loadscript/loadscript';

export default {
  name: 'generic-banner',
  components: {SpotscanEmbed, LoadScript},

  props: {
    initialData: {type: Object, required: true},
    dsfData: {type: Object, required: true},
    dsfPageCategory: {type: String, required: true},
    ctaClass: {type: String, default: 'btn'},
    btnTagging: {type: Object, required: true},
    dsfTagging: {type: Object, required: true},
    isRtl: {type: Boolean, default: false}
  },

  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      imageData: '',
      screenSize: window.matchMedia('(max-width: 767px)').matches
        ? 'small'
        : window.matchMedia('(min-width: 768px) and (max-width: 1023px)')
          .matches
          ? 'medium'
          : 'large'
    };
  },
  mounted() {
    $(function () {
      // your code goes here
      $('.focuspoint').focusPoint();
    });
    /*
          window.addEventListener("DOMContentLoaded", (event) => {
             this.focusMethod();
          }); */
    console.log('screen sizessss' + this.smallScreen);
  },
  methods: {
    /*focusMethod() {
       $('.focuspoint').focusPoint();
    },*/
    getScreenWidthHalf() {

      let screenWidth = '';
      let height = 256;
      var element = document.getElementById('content');
      var positionInfo = element.getBoundingClientRect();
      var width = positionInfo.width;
      if (this.screenSize == 'small') {
        height = 270;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'medium') {
        height = 400;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'large') {
        height = 256;
        screenWidth = 512;
        return 'width:' + screenWidth + 'px; height:' + height + 'px;';
      }
    },
    getScreenWidth(param = null) {

      let height = 450;
      var element = document.getElementById('content');
      var positionInfo = element.getBoundingClientRect();
      if (param === 'option2' || param === 'option1') {
        var width = positionInfo.width - 40;
      } else {
        var width = positionInfo.width;
      }

      var screenWidth = '';
      console.log(width);
      if (this.screenSize == 'small') {
        height = 270;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'medium') {
        height = 400;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'large') {
        height = 450;
        if (param === 'option2' || param === 'option1') {
          screenWidth = 1024;
          return 'width:100%; height:' + height + 'px;';
        } else {
          screenWidth = window.innerWidth;
          return 'width:100%; height:' + height + 'px;';
        }


      }

    },
    gaTagging() {
      if (this.btnTagging.category === 'error page') {

        window.dataLayer.push({
          event: 'uaevent',
          ecommerce: 'undefined',
          eventLabel: 'return::go back to home',
          eventAction: 'select',
          eventCategory: 'body',
        });
      } else {
        let eventLable = this.btnTagging.desktoplabel;
        if (this.smallScreen) {
          eventLable = this.btnTagging.desktoplabel;
        } else {
          eventLable = this.btnTagging.mobilelabel;
        }
        window.dataLayer.push({
          event: 'uaevent',
          event_name: 'body_button_click',
          ecommerce: 'undefined',
          eventLabel: eventLable,
          eventAction: this.btnTagging.action,
          eventCategory: this.btnTagging.category,
          eventAction: this.btnTagging.action,
          eventLabel: ctaname.toLowerCase() + '::' + url,
          cta_name: ctaname.toLowerCase(),
          link_url: url,
          module_name: this.decodeHtml(eventLable).toLowerCase(),

        });
      }
    },
    titleMobileText(MobileTitle, DesktopTitle, EnableMobileVersion) {
      var title = '';
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (EnableMobileVersion && MobileTitle != '') {
          title = MobileTitle
        } else if (EnableMobileVersion && MobileTitle == '') {
          title = DesktopTitle;
        } else if (EnableMobileVersion === false) {
          title = DesktopTitle;
        }

      }
      return title;

    },
    titleMobileDescription(MobileDescription, DesktopDescription, EnableMobileVersion) {
      var description = '';
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (EnableMobileVersion && MobileDescription != '') {
          description = MobileDescription
        } else if (EnableMobileVersion && MobileDescription == '') {
          description = DesktopDescription;
        } else if (EnableMobileVersion === false) {
          description = DesktopDescription;
        }

      }
      return description;

    },
    desktopalt(bannerlargealt, cropalt) {
      var altTag = cropalt;
      if (bannerlargealt) {
        altTag = bannerlargealt;
      }
      return altTag;
    },
    mobilealt(cropalt, mobile) {
      var mobileAltTag = cropalt;
      if (mobile) {
        mobileAltTag = mobile;
      }
      return mobileAltTag;

    },
    imageMobile(DesktopCropImageMedium, mobileimage, EnableMobileVersion) {
      var smallImage = '';

      if (EnableMobileVersion && mobileimage !== '') {
        smallImage = mobileimage;
      } else {
        smallImage = DesktopCropImageMedium;
      }

      return smallImage;

    },
    ctalable(mobile, deskstop, EnableMobileVersion) {
      var currentCtaLable = '';
      if (EnableMobileVersion) {
        if (mobile === '') {
          currentCtaLable = deskstop;
        } else {
          currentCtaLable = mobile;
        }
      } else {
        currentCtaLable = deskstop;
      }
      return currentCtaLable;

    },
    bgimage(cropimglarge, cropimgemedium, mobileimage) {
      var imgurl = `background-image:url('${cropimglarge}')`;
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (mobileimage) {
          imgurl = `background-image:url('${mobileimage}')`;
        }
        if (mobileimage == '') {
          imgurl = `background-image:url('${cropimgemedium}')`;
        }
      }
      return imgurl;
    },
    decodeHtml(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas = div.innerText.replaceAll('\n', ' ');
      return datas;
    }

  }
};
</script>

<style lang='scss' src="./generic-banner.scss"></style>
<style lang='css' src="./helper-tool.css"></style>
<style lang='css' src="./focuspoint.css"></style>
