var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list",attrs:{"id":_vm.productListId}},[(!_vm.products)?[_vm._t("title"),_vm._v(" "),_vm._t("default",null,{"productpopin":_vm.openProductPopin})]:[(_vm.showTitle)?_vm._t("title"):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"product-list container--is-maxwidth",class:_vm.getListType},_vm._l((_vm.products),function(product,index){return _c('li',{key:index,staticClass:"product-list__item"},[_c('input',{attrs:{"type":"hidden","data-product-id":`${product.productId}`},domProps:{"value":JSON.stringify(product)}}),_vm._v(" "),(_vm.renderItems && (_vm.getListType === 'article' || _vm.getListType === 'simple'))?_c('article-card',{attrs:{"src-set":product.srcset,"img-src":product.src,"img-alt":product.alt,"item-url":product.href,"item-title":product.title,"tagging":{ label: product.titleNoHtml, category: product.pageType },"cta-label":_vm.ctaLabelDecoded}}):(_vm.renderItems && _vm.getListType === 'ingredient')?[_c('div',{staticClass:"ingredient"},[_c('a',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
                action: product.action,
                label: product.ingredientName,
                category: product.category,
              }),expression:"{\n                action: product.action,\n                label: product.ingredientName,\n                category: product.category,\n              }",arg:"useractionevent"}],staticClass:"ingredient__name",attrs:{"href":product.ingredientLink,"tabindex":"0"},domProps:{"innerHTML":_vm._s(product.ingredientName)}}),_vm._v(" "),_c('p',{staticClass:"ingredient__content",domProps:{"innerHTML":_vm._s(product.ingredientContent)}}),_vm._v(" "),_c('a',{class:_vm.ctaClass,attrs:{"href":product.ingredientLink}},[_c('span',{staticClass:"btn__wrapper"},[_vm._v("\n                "+_vm._s(_vm.ctaLabelDecoded)+"\n              ")])])])]:(
            _vm.renderItems &&
            (_vm.getListType === 'concern' || _vm.getListType === 'range' || _vm.getListType === 'mol')
          )?_c('concern-card',{attrs:{"src-set":product.srcset,"img-src":product.src,"img-alt":product.alt,"item-url":product.href,"item-title":product.description,"tagging":{ label: product.descriptionNoHTml, category: product.pageType },"cta-label":_vm.ctaLabelDecoded}}):((!_vm.listItems && _vm.getListType === '') || _vm.getListType === 'product')?_c('product-card',{attrs:{"product-name":{ text: product.productName, html: product.productNameHtml },"product-short-description":product.productShortDescription,"product-url":product.productUrl,"tagging":product.tagging,"packshot":product.packshot,"cta-label":_vm.ctaLabelDecoded,"star-rating-text":product.starRatingText,"average-rating":Number(product.averageRating),"inactive-color":product.inactiveColor,"active-color":product.activeColor,"is-rating-enabled":`${(String(product.isRatingEnabled).toLowerCase() === 'true')}`,"is-bazaarvoice-activated":`${(String(product.IsBazaarvoiceActivated).toLowerCase() === 'true')}`,"border-color":product.activeColor,"product-id":`${product.productId}`,"product-item-id":`${product.productItemId}`,"border-width":"1","aria-hidden":"true","is-buynow-enabled":`${(String(_vm.buyNowEnable).toLowerCase() === 'true')}`,"buynow-cta-text":_vm.buyNowLabel,"total-rating-count":product.starRatingText,"data-variant-ean":"","initial-data":"","data-bynow-tagging":"","error-messgage":"","product-data-id":product.productId,"page-category":_vm.pageCategory}}):_vm._e()],2)}),0),_vm._v(" "),_vm._t("loadmore")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }