<template>
  <div class="a-star-rating-wrapper">
    <star-rating
      v-bind="$props"
      :border-width="1"
      :read-only="true"
      :show-rating="false"
    />
    <span aria-label="rating">{{ totalRatingCountInBrackets }}</span>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  components: {
    StarRating,
  },
  props: {
    ...StarRating.props,
    totalRatingCount: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    totalRatingCountInBrackets () {
      if (this.totalRatingCount) return `(${this.totalRatingCount})`;
      return `(${this.rating})`;
    }
  }
}
</script>

<style>
.a-star-rating-wrapper {
  display: flex;
  align-items: center;
}
</style>
