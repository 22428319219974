<template>
  <div class="article-push">
    <div class="article-push__content">
      <responsive-image aspect-ratio="69" :srcset="srcSet">
        <img src="/frontend/master/images/placeholder.png" alt="">

        <noscript>
          <img :src="imgSrc" :alt="imgAlt">
        </noscript>
      </responsive-image>

      <a :href="itemUrl"
         class="article-push__link btn__outside-elm"
         tabindex="0"
         v-tag:useractionevent="{
            label: tagging.label, 
            category: tagging.category, 
            action: 'select::articles',
            event_name: 'body_button_click',
            module_name: 'article card',
            cta_name: tagging.label,
            link_url: currentUrl() + itemUrl
          }" v-html="itemTitle"/>

      <div class="cta--secondary cta--light">
        <span class="btn__wrapper">
          {{ ctaLabel }}
        </span>
      </div>

    </div>
  </div>
</template>
<script>
  import { tag } from '@Foundation/analyticsHandler';
  import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';

  export default {
    name: 'article-card',
    directives: {
      tag
    },
    components: {
      ResponsiveImage
    },
    props: {
      srcSet: {
        type: Object,
        required: false,
        default: () => ({})
      },

      imgSrc: {
        type: String,
        required: false,
        default: undefined
      },

      imgAlt: {
        type: String,
        required: false,
        default: 'image'
      },

      itemTitle: {
        type: String,
        required: true,
        default: undefined
      },

      itemUrl: {
        type: String,
        required: true,
        default: undefined
      },

      tagging: {
        type: Object,
        required: true,
        default: undefined
      },

      ctaLabel: {
        type: String,
        required: false,
        default: ''
      }
    },
     methods: {      
      currentUrl(){
          return window.location.origin;
      },
     }
  };
</script>
