<template>
  <div class="product-list-container">
    <div class="product-list__numberofproducts" v-if="listItems.LoadMore && !getNoMoreProducts">
      {{ getTotalProductCount }} {{ listItems.ProductLabel || 'PRODUCTS' }}
    </div>
    <ul v-if="!getNoMoreProducts && !getIsFilterClick" class="product-list">
      <template v-for="(product, index) in getFilterProducts">
        <li
          v-if="product.RangeType !== null"
          :key="index"
          class="product-list__item"
          :class="product.RangeType === 'Double Column' ? 'doubleColumn' : ''"
        >
          <product-card
            v-if="product.RangeType === 'ProductList'"
            :product-name="{ text: product.Name, html: product.Name }"
            :product-short-description="product.Description"
            :product-url="product.CTALink"
            :tagging="product.TaggingEvents"
            :packshot="product.Packshot"
            :cta-label="listItems.CTAText"
            :is-buynow-enabled="listItems.IsBuyNowEnabled"
            :buynow-cta-text="listItems.BuyNowCTAText"
            :average-rating="Number(product.AverageRating)"
            :inactive-color="listItems.InActiveColor"
            :active-color="listItems.ActiveColor"
            :is-rating-enabled="String(listItems.IsRatingEnabled)"
            :is-bazaarvoice-activated="listItems.IsBazaarvoiceActivated"
            :border-color="listItems.BorderColor"
            aria-hidden="true"
            :product-tag="product.ProductTag"
            :total-rating-count="product.TotalRatingCount"
            :position="index"
            :product-id="product.ProductId"
            :product-data-id="product.ItemID"
            :page-category="pageCategory"
          />
          <template
            v-else-if="product.RangeType === 'Double Column' && product.PushType === 'Option2'"
          >
            <!-- quote component takes place here -->
            <big-quote
              :title="product.Name"
              :author-icon="product.Packshot.MainImage"
              :alt-text="product.Packshot.ImageAlt"
              :author-name="product.ProductTag"
              :author-description="product.Description"
              :title-hn-tag="product.ProductEanNumber"
              color-theme=""
              background-color=""
            ></big-quote>
          </template>
          <template v-else>
            <!-- content push component takes place here -->
            <content-push :content-push="product" :position="index" :page-category="pageCategory"></content-push>
          </template>
        </li>
      </template>
    </ul>
    <div v-else-if="getNoMoreProducts" class="noResult"><h4>{{ getTotalProductCount }} {{ listItems.ProductLabel || 'PRODUCTS' }}</h4><p v-html="listItems.ZeroProductMessage"></p></div>
    <div class="loader-container">
      <div ref="loadMore" class="loadMore-trigger"></div>
      <transition name="fade">
        <div v-show="productsLoading" class="loader-css">Loading</div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { QueryToObject } from '../../services/query-to-object';

export default {
  name: 'product-range-list',
  components: {
    'product-card': () => import('../product-card/product-card.vue'),
    'content-push': () => import('@Feature/ContentPush/code/Scripts/components/contentPush.vue'),
    'big-quote': () => import('@Feature/BigQuote/code/Scripts/components/big-quote.vue'),
  },
  props: {
    rangeData: {
      type: Object,
      default() {
        return null;
      },
    },
    apiUrl: {
      type: String,
      required: true,
      default: 'api/lrpproductlist/ProductListDetailsLoadMore',
    },
    pageCategory :{
      type: String
    }
  },
  data() {
    return {
      onLoad: true,
      loading: false,

      nextItem: 1,
      
      postDataObj: {
        ProductListId: `${this.rangeData.ProductListId}`,
        Age: '',
        Concern: '',
        Format: '',
        NewOrBestseller: '',
        PPD: '',
        ProductType: '',
        SkinBenefit: '',
        SkinType: '',
        SPF: '',
        SubRange: '',
        SunProtectionType: '',
        Texture: '',
        ValidityAfterOpening: '',
        Zone: '',
        skipCount: 12,
        takeCount: 12,
        filter: false,
        SortBy: '',
      },
      listItems: {},
      stopLoadMore: false,
      stop: false
    };
  },
  computed: {
    ...mapGetters(['getFilterProducts']),
    ...mapGetters(['getStopApIcall']),
    ...mapGetters(['getNoMoreProducts','productsLoading','getTotalProductCount','getIsFilterClick']),
    isFilterEnabled() {
      let isFitlerEnabled = false;
  
      let queries = QueryToObject(window.location.search);
  
      let queryParamKeys = Object.keys(queries);
  
      for(let i = 0; i < queryParamKeys.length; i++) {
        if(queries[queryParamKeys[i].length]) {
          isFitlerEnabled = true;
          break;
        }
      }

      return isFitlerEnabled;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.listItems = this.rangeData;
    this.$store.dispatch('storeProductListId',this.listItems.ProductListId,);
    this.$store.dispatch('storeInitialData',this.listItems.RangeList);
    this.$store.dispatch('storeTotalProductCount',this.listItems.TotalProductCount);
    this.$store.dispatch('storeZeroProductMessage', this.listItems.ZeroProductMessage);
  },
  methods: {
    async handleScroll() {
      if(this.isFilterEnabled && this.onLoad) {
        this.onLoad = false;
      }
      else {
        const rect = this.$refs.loadMore.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight && !this.stop  &&
          (this.listItems.LoadMore || this.listItems.LoadMore === 'true') && !this.getStopApIcall
        ) {
          this.stop = true;
 
          this.getFilterProducts.length >= 12 && await this.$store.dispatch('fetchFilterProducts',false);
         
          this.stop = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" src="./product-range-list.scss"></style>
