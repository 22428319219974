<template>
  <div class="product-slider container--is-maxwidth">
    <h2 class="product-slider__title" v-if="productSliderData.Title !== ''"  v-html="productSliderData.Title"></h2>
      <template>
        <div class="product-slider-list" id="swiper-product-slider" :class="[desktopScreen && productSliderData.ProductListDetails.length < 4 ? 'align-center' : '']">
          <swiper :options="swiperOptions" ref="swiper" class="swiper" :class="{'three-slider' : productSliderData.ProductListDetails.length >= 3}">
             <swiper-slide v-for="(product, index) in productSliderData.ProductListDetails" :key="index" class="product-slider__item">
              <ProductCard 
                :product-name="{ text: product.Name, html: product.Name }"
                :product-short-description="product.Description"
                :product-url="product.CTALink"
                :tagging="product.TaggingEvents"
                :packshot="product.Packshot"
                :cta-label="ctaLabel"
                :is-buynow-enabled="productSliderData.IsBuyNowEnabled"
                :buynow-cta-text="productSliderData.BuyNowCTAText"
                :average-rating="Number(product.AverageRating)"
                :inactive-color="product.InActiveColor"
                :active-color="product.ActiveColor"
                :is-rating-enabled="String(product.IsRatingEnabled)"
                :is-bazaarvoice-activated="product.IsBazaarvoiceActivated"
                :border-color="product.BorderColor"
                aria-hidden="true"
                :total-rating-count="product.TotalRatingCount"
                :position="index"
                :product-id="product.ProductId"
                :page-category="pageCategory"
                :title-tag-name="titleTagName"
              />
            </swiper-slide>
          </swiper>
        </div>
        <div v-show="(desktopScreen && productSliderData.ProductListDetails.length > 4) || !desktopScreen" class="carousel-navigation" >
          <div class="carousel__arrow carousel__arrow--left" :class="{ 'disabled': swiperAtBegain }" @click="prev()">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 42 32" fill="none">
              <g filter="url(#filter0_b_17_13510)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 15.8535L3.85356 12L4.56066 12.7071L1.9142 15.3535L41.8605 15.3535V16.3535L1.91422 16.3535L4.56066 19L3.85355 19.7071L0 15.8535Z" fill="black"/>
              </g>
              <defs>
                <filter id="filter0_b_17_13510" x="-124" y="-124" width="290" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="62"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_17_13510"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_17_13510" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
          <div class="swiper-pagination"></div>
          <div class="carousel__arrow carousel__arrow--right" :class="{ 'disabled': swiperAtEnd }" @click="next()">
            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 42 32" fill="none">
              <g filter="url(#filter0_b_17_13509)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42 15.8535L38.1464 12L37.4393 12.7071L40.0858 15.3535L0.13953 15.3535V16.3535L40.0858 16.3535L37.4393 19L38.1465 19.7071L42 15.8535Z" fill="black"/>
              </g>
              <defs>
                <filter id="filter0_b_17_13509" x="-124" y="-124" width="290" height="280" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="62"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_17_13509"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_17_13509" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </template>
      <a @click="discoverBtn()" :href="productSliderData.CtaLink" class="cta--secondary cta--light cta--alt"> <span>{{productSliderData.CtaLabel}}</span></a>
    </div>
</template>

<script>
import ProductCard from '../product-card/product-card.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name : 'product-slider',
  props: {
    productSliderData: {
      type: Object,
      default: () => []   
    },
    ctaLabel: {
      type: String,
      default: ''
    },
    pageCategory: {
      type: String,
      default: 'none'
    }
  },

  data() {
    return {
      titleTagName:'',
      isSmallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      desktopScreen: window.matchMedia('(min-width: 768px)').matches,
      swiperAtEnd: false,
      swiperAtBegain: true,
      swiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 500,
        slidesPerView: 4,
        spaceBetween: 16,
        roundLengths: true,
        variableWidth: true,
        resizeObserver: true,
        startX:'',
        navigation: {
          nextEl: '.carousel__arrow carousel__arrow--left',
          prevEl: '.carousel__arrow carousel__arrow--right',
          hiddenClass: '.swiper-button-hidden',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
            spaceBetween: 16,
          },
          360: {
            centeredSlides: true,
            slidesPerView: 1.6,
            spaceBetween: 24
          },
          320: {
            centeredSlides: true,
            slidesPerView: 1.25,
            slidesPerView: 1.6,
            spaceBetween: 24
          },
        },
      },
      productList:[],
      isTrigger: true,
    }
  },
 created(){
        this.productList = [];
        for (var i = 0; i < this.productSliderData.ProductListDetails.length; i++) {
             if(this.productSliderData.ProductListDetails[i].TaggingEvents != undefined) {          
             this.productList.push(this.productSliderData.ProductListDetails[i].TaggingEvents);
          }
        }
        this.productList.forEach(object => {
                  delete object['currency'];
        });
  },
  mounted() {
    this.titleTagName = this.productSliderData.Title !== '' ? 'h3' : 'h2'
    this.$refs.swiper.swiperInstance.on('slideChange', () => {
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
    });
    const touchArea = document.getElementById('swiper-product-slider');
    touchArea.addEventListener('touchstart',this.handleTouchStart);
    touchArea.addEventListener('touchmove',this.handleTouchMove);
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleTouchStart(event){
      this.startX = event.touches[0].clientX;
    },
    handleTouchMove(event){
      let currentX = event.touches[0].clientX;
 
      if (this.startX < currentX) {
        this.prev();
      } else if (this.startX > currentX) {
        this.next();
      }
    },
    removeHTMLTags(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        let datas=div.innerText.replaceAll('\n', ' ').trim();
        var parser = new DOMParser();
        var parsedHtml = parser.parseFromString(datas, 'text/html');
        var withoutBrTags = parsedHtml.body.textContent || "";
        return withoutBrTags;
      },
    prev() {
          this.$refs.swiper.$swiper.slidePrev();
          this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
          this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
          const currentProduct = this.productSliderData.ProductListDetails[this.$refs.swiper.$swiper.realIndex];
          window.dataLayer.push({
            event: 'uaevent',
          ecommerce: 'undefined',
          event_name: 'slider_button_click',
          eventCategory: `${this.pageCategory}`,
          eventAction: 'select::slider navigation elements',
          eventLabel: this.removeHTMLTags(this.productSliderData.Title).toLowerCase()+ '::' + this.removeHTMLTags(currentProduct.Name).toLowerCase()
    + '::' +this.removeHTMLTags(currentProduct.ProductEanNumber).toLowerCase(),
          cta_name: this.removeHTMLTags(this.productSliderData.Title).toLowerCase()+ '::' + this.removeHTMLTags(currentProduct.Name).toLowerCase()
    + '::' +this.removeHTMLTags(currentProduct.ProductEanNumber).toLowerCase(),
          module_name: 'slider navigate::' + this.removeHTMLTags(this.productSliderData.Title).toLowerCase()
          });
    },
  next() {
      this.$refs.swiper.$swiper.slideNext();
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      const currentIndex = this.$refs.swiper.$swiper.realIndex;
      const slidesPerView = parseInt(this.swiperOptions.slidesPerView, 10) || 1;
      const desiredIndex = currentIndex + slidesPerView-1;
      if (desiredIndex < this.productSliderData.ProductListDetails.length) {
        const currentProduct = this.productSliderData.ProductListDetails[desiredIndex];
        window.dataLayer.push({
          event: 'uaevent',
          ecommerce: 'undefined',
          event_name: 'slider_button_click',
          eventCategory: `${this.pageCategory}`,
          eventAction: 'select::slider navigation elements',
          eventLabel: this.removeHTMLTags(this.productSliderData.Title).toLowerCase()+ '::' + this.removeHTMLTags(currentProduct.Name).toLowerCase()
    + '::' +this.removeHTMLTags(currentProduct.ProductEanNumber).toLowerCase(),
          cta_name: this.removeHTMLTags(this.productSliderData.Title).toLowerCase()+ '::' + this.removeHTMLTags(currentProduct.Name).toLowerCase()
    + '::' +this.removeHTMLTags(currentProduct.ProductEanNumber).toLowerCase(),
          module_name: 'slider navigate::' + this.removeHTMLTags(this.productSliderData.Title).toLowerCase()
        });
      } 
   },
    discoverBtn(){
      var href = this.productSliderData.CtaLink;
			if (href && href.indexOf('https') === -1) {
			  var destinationURL = window.location.origin + this.productSliderData.CtaLink;
			}else{
			var destinationURL =this.productSliderData.CtaLink;
			}
      var ctaName=this.productSliderData.CtaLabel;
      window.dataLayer.push({
        event:'uaevent',
        ecommerce:'undefined',
        event_name:'body_button_click',
        eventCategory:`${this.pageCategory}`,
        eventAction:'select::'+this.removeHTMLTags(this.productSliderData.Title).toLowerCase(),
        eventLabel:`${ctaName.toLowerCase()}` +'::'+ `${destinationURL}`,
        module_name:this.removeHTMLTags(this.productSliderData.Title).toLowerCase(),
        cta_name:`${ctaName.toLowerCase()}`,
        link_url:`${destinationURL}`
      });
    },
     gaTagging(){      
          AnalyticsHandler.pushDataLayer({
            'event': 'nievent',
            'event_name': 'view_item_list',
            'eventCategory': 'Ecommerce',
            'eventAction': 'Product Impressions',
            'eventLabel': 'Product detail page',
            'ecommerce': {
              'currencyCode': 'none',
              'impressions': this.productList
            }
          });
      },
      handleScroll(){  
          const observer = new IntersectionObserver(entries => {
               entries.forEach(entry => {
                if(this.isTrigger){
                   const intersecting = entry.isIntersecting 
                    if(intersecting){               
                        this.gaTagging();
                        this.isTrigger= false;
                    }
                }
               })
          })
          if(document.getElementById("swiper-product-slider") !=''){           
              observer.observe(document.getElementById("swiper-product-slider"));
           }          
      },
  },
  
  components: {
    ProductCard,
    Swiper,
    SwiperSlide,
  }
}
</script>

<style lang="scss" src="./product-slider.scss"></style>