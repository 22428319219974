var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-push"},[_c('div',{staticClass:"article-push__content"},[_c('responsive-image',{attrs:{"aspect-ratio":"69","srcset":_vm.srcSet}},[_c('img',{attrs:{"src":"/frontend/master/images/placeholder.png","alt":""}}),_vm._v(" "),_c('noscript',[_c('img',{attrs:{"src":_vm.imgSrc,"alt":_vm.imgAlt}})])]),_vm._v(" "),_c('a',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
          label: _vm.tagging.label, 
          category: _vm.tagging.category, 
          action: 'select::articles',
          event_name: 'body_button_click',
          module_name: 'article card',
          cta_name: _vm.tagging.label,
          link_url: _vm.currentUrl() + _vm.itemUrl
        }),expression:"{\n          label: tagging.label, \n          category: tagging.category, \n          action: 'select::articles',\n          event_name: 'body_button_click',\n          module_name: 'article card',\n          cta_name: tagging.label,\n          link_url: currentUrl() + itemUrl\n        }",arg:"useractionevent"}],staticClass:"article-push__link btn__outside-elm",attrs:{"href":_vm.itemUrl,"tabindex":"0"},domProps:{"innerHTML":_vm._s(_vm.itemTitle)}}),_vm._v(" "),_c('div',{staticClass:"cta--secondary cta--light"},[_c('span',{staticClass:"btn__wrapper"},[_vm._v("\n        "+_vm._s(_vm.ctaLabel)+"\n      ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }