import Vue from 'vue';
import BannerModule from '@Feature/Banner';
import Carousel from '@Feature/Carousel';
import CollapseModule from '@Feature/Collapse';
import FlipperModule from '@Feature/Flipper';
import JumpToModule from '@Feature/JumpTo';

import LoaderModule from '@Feature/Loader';
import MainMenuModule from '@Feature/MainMenu';
import GeoPopinModule from '@Feature/GeoPopin';
import ResponsiveImageModule from '@Feature/ResponsiveImage';
import RoutineModule from '@Feature/Routine';
import SelectMultipleModule from '@Feature/SelectMultiple';
import SmileyModule from '@Feature/SmileyVote';
import SpriteBackgroundModule from '@Feature/SpriteBackground';
import SprinklrModule from '@Feature/Sprinklr';
import NoJsHandler from '@Feature/NoJsHandler';
import VideoPlayerModule from '@Feature/VideoPlayer';
import CookieBannerModule from '@Feature/CookieBanner';
import SpotscanEmbedModule from '@Feature/SpotscanEmbed';
import ArticleTagging from '@Feature/articleTagging';
import ArticleList from '@Feature/ArticleList';
import GenericBannerYoutubePlayer from '@Feature/GenericBannerYoutubePlayer';
import HeaderBannerModule from '@Feature/HeaderBanner';
import TextBannerModule from '@Feature/TextBanner';
import NewsletterSubscription from '@/Feature/NewsletterSubscription';
import GenericBanner from '@Feature/GenericBanner';
import MySkinSos from '@Feature/FEMySkinSOS';
import ImagesMosaic from '@Feature/ImagesMosaic';
import NewsletterModule from '@Feature/Newsletter';
import contrastSwitcherButton from '@Feature/contrastSwitcherButton';
import DataPush from '@Feature/DataPush';
import ArticlePushV2 from '@Feature/ArticlePush-V2';
import BigQuote from '@Feature/BigQuote';
import successModal from '@Feature/SuccessModal';
import Modal from '@Feature/Modal';
import LanguageSwitcher from '@Feature/LanguageSwitcher';
import ProductsModule from '@Feature/LrpProducts';
import AccessibilityModalWindow from '@Feature/AccessibilityModalWindow';
import BrandContentHub from '@Feature/BrandContentHub';
import VueScrollSnap from '@Feature/VueScrollSnap';
import BackToTop from '@Feature/BackToTop';
import DiagnosisTriggerModule from '@Feature/DiagnosisTrigger';
import NewsletterSubscriptionV2 from '@/Feature/NewsletterSubscription-V2';
import productPopin from '@Feature/LrpProducts/productPopin';
import NewsletterSticky from '@Feature/NewsletterSticky';
import footerBanner from '@Feature/FooterBanner';
import HomePageTransparencySlider from '@Feature/HomePageTransparencySlider';
import articleProven from '@Feature/articleProven';
import safeSpace from '@Feature/safeSpace';
import articleHeader from '@Feature/Articleheader';

import { AnalyticsHandler, default as AnalyticsHandlerModule } from '@Foundation/analyticsHandler';
import { customFormAnalytics } from '@Foundation/directives/customFormAnalytics/customFormAnalytics';
import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
import { clickextension } from '@Foundation/directives/clickExtension/clickextension';
import { preventBodyScroll } from '@Foundation/directives/preventBodyScroll/preventBodyScroll';
import { background } from '@Foundation/directives/background/background';
import { ellipsis } from '@Foundation/directives/ellipsis/ellipsis';
import { imageloaded } from '@Foundation/directives/imageloaded/imageloaded';
import { srcset } from '@Foundation/directives/srcset/srcset';
import { debug } from '@Foundation/env/env';
import { InputHandler } from '@Foundation/inputHandler';
import LoadScriptModule from '@Foundation/loadscript';
import MqHandler from '@Foundation/mqHandler/MqHandler';
import { KeySiteCore } from '@Foundation/sitecore';
import { VueModule } from '@Foundation/utilities';
import '@Foundation/wsf/wsf';
// Project
import '@Themes/index.scss';
import loadZoneCustomizations from '@loreal/zone-plugin-loader';

import './registerServiceWorker';
import { store } from './store';

Vue.use(KeySiteCore);
// Configure MqHandler
MqHandler.nomockgetMqHandler({
  small: 768,
  medium: 1024,
});
// Configure AnalyticsHandler
AnalyticsHandler.getAnalyticsHandler(debug);

// Configure InputHandler
InputHandler.getInputHandler();

// Configuration ignored amp-* component
Vue.config.ignoredElements = [
  'amp-story',
  'amp-story-page',
  'amp-img',
  'amp-story-grid-layer',
  'amp-video',
  'amp-story-bookend',
];

// Configuration ignored amp-* component
Vue.config.keyCodes = {
  home: 36,
  end: 35,
};
/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/v2/guide/plugins.html#Writing-a-Plugin
 * */

loadZoneCustomizations(Vue);

if (screen.width <= 600 && document.getElementsByClassName('fb_customer_chat_icon')[0]) {
  document.getElementsByClassName('fb_customer_chat_icon')[0].style.bottom = '65px !important';
}

const app = VueModule({
  asyncImports: [
    // {
    //   module: async () =>
    //     import(/* webpackPrefetch: true, webpackChunkName: "rfp-iframe" */ '@Feature/IFrame'),
    //   components: ['rfpIframeMainContainer', 'rfpIframeLoader', 'rfpIframeError'],
    // },
    {
      module: async () => import(/* webpackChunkName: "youtube-player" */ '@Feature/YoutubePlayer'),
      components: ['YoutubePlayer'],
    },
    {
      module: async () =>
        import(/* webpackChunkName: "add-to-homescreen" */ '@Feature/Notification'),
      components: ['Notification'],
    },
  ],
  imports: [
    // Foundation
    AnalyticsHandlerModule,
    LoadScriptModule,
    // Feature
    ResponsiveImageModule,
    BannerModule,
    HeaderBannerModule,
    TextBannerModule,
    NewsletterSubscription,
    GeoPopinModule,
    FlipperModule,
    MainMenuModule,
    Carousel,
    CollapseModule,
    //ProductList,
    VideoPlayerModule,
    SmileyModule,
    SpriteBackgroundModule,
    RoutineModule,
    SelectMultipleModule,
    LoaderModule,
    JumpToModule,
    RoutineModule,
    // packShot,
    ImagesMosaic,
    contrastSwitcherButton,
    // ProgressBarModule, will use in the next sprint
    ArticleTagging,
    ArticleList,
    GenericBannerYoutubePlayer,
    GenericBanner,
    ArticlePushV2,
    BigQuote,
    successModal,
    Modal,
    // 3rd party
    NewsletterModule,
    MySkinSos,
    NoJsHandler,
    SprinklrModule,
    CookieBannerModule,
    SpotscanEmbedModule,
    DataPush,
    LanguageSwitcher,
    ProductsModule,
    AccessibilityModalWindow,
    BrandContentHub,
    VueScrollSnap,
    BackToTop,
    DiagnosisTriggerModule,
    productPopin,
    NewsletterSticky,
    NewsletterSubscriptionV2,
    footerBanner,
    HomePageTransparencySlider,
    articleProven,
    safeSpace,
    articleHeader
  ],
  directives: {
    background,
    clickoutside,
    clickextension,
    srcset,
    imageloaded,
    ellipsis,
    customFormAnalytics,
    preventBodyScroll,
  },
  filters: {},
  store,
  components: {
    'rfp-lrp-ratings-star-rating-product': () =>
      import('@Feature/RatingsReviews/code/Scripts/LRP/rfp-lrp-ratings-star-rating-product.vue'),
  },
  methods: {
      openAccessibility() {
        document.getElementById('accessibility-popup').classList.add('show');
        window.eventBus.$emit('accessibility-click', true);
      }
    },
});

// Add plugins
new Vue(app).$mount('#main-container');


